const Help = ()=>{
     
    return(
        <div>
            <h1>Help & Support</h1>
            <h4>Let's take a step ahead and help you better.</h4>
            <div className="help-Contaner">
                <h3>FAQ</h3>
            </div>
        </div>
    )

}
export default Help;